import React, { useState } from "react";

import { Block } from "baseui/block";
import {
  H3,
  ParagraphXSmall,
  LabelXSmall,
  LabelSmall,
  Label3,
  LabelLarge,
} from "baseui/typography";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Button } from "baseui/button";
import queryString from "query-string";
import { SIZE as SIZEI } from "baseui/input";
import { useStyletron } from "baseui";
import Datepicker from "../../components/Datepicker/Datepicker";
import { Select } from "baseui/select";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { useAccessibility } from "../../contexts/AccessibilityContext";
import { format } from "date-fns";

import { backgroundContrast } from "./utils";
import { Pagination } from "@containers/Pagination";
import { getQueryString } from "@utils/get-query-string";
import { NInit } from "@namespace/Init";

type CProps = {
  data: any;
  response: any;
  layout: any;
  appSettings: NInit.IAppSettings;
};

const Stats: React.FC<CProps> = (props) => {
  const { items, translation, information } = props?.data;
  const { formatDate } = props?.layout;
  const { pathname, search } = useLocation();
  const {
    page = 1,
    size,
    searchInto: searchIntoParam,
    displayBy: displayByParam,
  } = queryString.parse(search, { parseNumbers: true });
  const { lang = "PL", from, to } = queryString.parse(search);
  const history = useHistory();

  const [css, theme] = useStyletron();

  const [startDate, setStartDate] = useState(
    from ? new Date(from as string) : new Date()
  );
  const [endDate, setEndDate] = useState(
    to ? new Date(to as string) : new Date()
  );

  const [searchInto, setSearchInto] = useState<any>(
    searchIntoParam ? [{ id: searchIntoParam }] : null
  );

  const [displayBy, setDisplayBy] = useState<any>({ id: displayByParam || 10 });

  const { fontSizeMultiplier } = useAccessibility();

  const { headers } = props?.response;

  const maxValue = Math.max(
    ...items.map((e: { rawValue: string }) => parseInt(e.rawValue))
  );

  return (
    <div>
      <Block
        overrides={{
          Block: {
            style: () => ({
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "stretch",
              flexWrap: "wrap",
              fontFamily: "Open-sans, sans-serif",
            }),
          },
        }}
      >
        <Block
          width={["100%", "100%", "60%", "70%"]}
          marginBottom={["20px", "20px", "0", "0"]}
          overrides={{
            Block: {
              style: () => ({
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "stretch",
              }),
            },
          }}
        >
          <Block
            overrides={{
              Block: {
                style: () => ({
                  backgroundColor: theme.colors.backgroundLightAccent,
                  padding: "30px 30px",
                  width: "100%",
                  borderRadius: "3px",
                  boxShadow:
                    "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
                  textDecoration: "none",
                  position: "relative",
                  boxSizing: "border-box",
                  height: "100%",
                }),
              },
            }}
          >
            <H3
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    color: $theme.colors.primary,
                    margin: "0px 0px 5px",
                    fontSize: fontSizeMultiplier! * 18 + "px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    fontWeight: 500,
                    textTransform: "uppercase",
                  }),
                },
              }}
            >
              {translation?.title}
            </H3>

            <Block
              overrides={{
                Block: {
                  style: () => ({
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "stretch",
                    paddingTop: "25px",
                    flexWrap: "wrap",
                  }),
                },
              }}
            >
              <Block width={["100%", "100%", "100%", "58%"]}>
                <ParagraphXSmall
                  as="div"
                  overrides={{
                    Block: {
                      style: () => ({
                        fontSize: fontSizeMultiplier! * 10 + "px",
                        margin: "0px 0 40px",
                        lineHeight: "11px",
                        color: theme.colors.accent50,
                        fontWeight: 400,
                        letterSpacing: ".75px",
                      }),
                    },
                  }}
                >
                  {translation?.filterBox?.title}
                </ParagraphXSmall>

                <div
                  className={css({
                    margin: "0px 0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <LabelXSmall
                    overrides={{
                      Block: {
                        style: () => ({
                          margin: "0px 10px 0px 0px",
                          color: theme.colors.accent50,
                          fontWeight: 400,
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          letterSpacing: ".25px",
                          textDecoration: "none",
                          minWidth: "75px",
                        }),
                      },
                    }}
                  >
                    {translation.filterBox?.searchInto?.title}
                  </LabelXSmall>
                  {translation.filterBox?.searchInto?.items?.length > 0 && (
                    <Select
                      options={translation.filterBox?.searchInto?.items?.map(
                        (item: { key: any; value: any }) => {
                          return { label: item.key, id: item.value };
                        }
                      )}
                      value={
                        searchInto || {
                          id: translation.filterBox?.searchInto?.items[0].value,
                        }
                      }
                      size={SIZEI.compact}
                      searchable={false}
                      placeholder={translation.filterBox?.searchInto?.title}
                      onChange={(params: { value: any }) =>
                        setSearchInto(params.value)
                      }
                      overrides={{
                        ValueContainer: {
                          style: () => ({
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "11px",
                            padding: "8px 8px",
                            backgroundColor: theme.colors.backgroundSecondary,
                            color: theme.colors.accent50,
                            height: "auto",
                          }),
                        },
                        SingleValue: {
                          style: () => ({
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "11px",
                            height: "auto",
                          }),
                        },
                        ControlContainer: {
                          style: () => ({
                            borderRadius: "3px",
                            boxShadow:
                              "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                            border: `1px solid ${theme.colors.borderSelected}`,
                            minWidth: "unset",
                            backgroundColor: `${theme.colors.backgroundSecondary}`,
                          }),
                        },
                        InputContainer: {
                          style: () => ({
                            height: "0px",
                          }),
                        },
                        Root: {
                          style: () => ({
                            backgroundColor: `${theme.colors.backgroundSecondary}`,
                          }),
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  className={css({
                    margin: "10px 0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <LabelXSmall
                    overrides={{
                      Block: {
                        style: () => ({
                          margin: "0px 10px 0px 0px",
                          color: theme.colors.accent50,
                          fontWeight: 400,
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          letterSpacing: ".25px",
                          textDecoration: "none",
                          minWidth: "75px",
                        }),
                      },
                    }}
                  >
                    {translation.filterBox?.displayBy?.title}
                  </LabelXSmall>

                  {translation.filterBox?.displayBy?.items?.length > 0 && (
                    <Select
                      options={translation.filterBox?.displayBy?.items?.map(
                        (item: { key: any; value: any }) => {
                          return { label: item.key, id: item.value };
                        }
                      )}
                      value={displayBy}
                      size={SIZEI.compact}
                      searchable={false}
                      placeholder={translation.filterBox?.searchInto?.title}
                      onChange={(params: { value: any }) =>
                        setDisplayBy(params.value)
                      }
                      overrides={{
                        ValueContainer: {
                          style: () => ({
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "11px",
                            padding: "8px 8px",
                            backgroundColor: theme.colors.backgroundSecondary,
                            color: theme.colors.accent50,
                            height: "auto",
                          }),
                        },
                        SingleValue: {
                          style: () => ({
                            fontSize: fontSizeMultiplier! * 11 + "px",
                            lineHeight: "11px",
                            height: "auto",
                          }),
                        },
                        ControlContainer: {
                          style: () => ({
                            borderRadius: "3px",
                            boxShadow:
                              "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                            border: `1px solid ${theme.colors.borderSelected}`,
                            minWidth: "unset",
                            backgroundColor: `${theme.colors.backgroundSecondary}`,
                          }),
                        },
                        InputContainer: {
                          style: () => ({
                            height: "0px",
                          }),
                        },
                        Root: {
                          style: () => ({
                            marginTop: "5px",
                            backgroundColor: `${theme.colors.backgroundSecondary}`,
                          }),
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  className={css({
                    margin: "10px 0px 0px 0px",
                    display: "flex",
                    alignItems: "center",
                  })}
                >
                  <LabelXSmall
                    overrides={{
                      Block: {
                        style: () => ({
                          margin: "0px 10px 0px 0px",
                          color: theme.colors.accent50,
                          fontWeight: 400,
                          fontSize: fontSizeMultiplier! * 10 + "px",
                          letterSpacing: ".25px",
                          textDecoration: "none",
                          width: "75px",
                        }),
                      },
                    }}
                  >
                    {translation.filterBox?.date}
                  </LabelXSmall>
                  <div
                    className={css({
                      width: "110px",
                      marginRight: theme.sizing.scale600,
                    })}
                  >
                    <Datepicker
                      value={startDate}
                      onChange={(a: { date: Date }) => setStartDate(a.date)}
                      maxDate={endDate}
                      lang={lang}
                      aria-label="start date"
                      formatString={formatDate}
                      overrides={{
                        Input: {
                          props: {
                            size: SIZEI.compact,
                            overrides: {
                              Input: {
                                style: {
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  lineHeight: "11px",
                                  padding: "8px 8px",
                                  backgroundColor:
                                    theme.colors.backgroundSecondary,
                                  color: theme.colors.accent50,
                                },
                              },
                              InputContainer: {
                                style: {
                                  borderRadius: "3px",
                                  boxShadow:
                                    "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                                  border: `1px solid ${theme.colors.borderSelected}`,
                                  minWidth: "unset",
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                  <div
                    className={css({
                      width: "110px",
                    })}
                  >
                    <Datepicker
                      lang={lang}
                      value={endDate}
                      onChange={(a: { date: Date }) => setEndDate(a.date)}
                      maxDate={new Date()}
                      minDate={startDate}
                      formatString={formatDate}
                      aria-label="end date"
                      overrides={{
                        Input: {
                          props: {
                            size: SIZEI.compact,
                            overrides: {
                              Input: {
                                style: {
                                  fontSize: fontSizeMultiplier! * 11 + "px",
                                  lineHeight: "11px",
                                  padding: "8px 8px",
                                  backgroundColor:
                                    theme.colors.backgroundSecondary,
                                  color: theme.colors.accent50,
                                },
                              },
                              InputContainer: {
                                style: {
                                  borderRadius: "3px",
                                  boxShadow:
                                    "0.707px 0.707px 9.4px 0.6px rgba(119, 119, 119, 0.1)",
                                  border: `1px solid ${theme.colors.borderSelected}`,
                                  minWidth: "unset",
                                },
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </Block>

              <Block
                width={["100%", "100%", "100%", "38%"]}
                paddingTop={["20px", "20px", "20px", "0px"]}
                overrides={{
                  Block: {
                    style: {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    },
                  },
                }}
              >
                <Button
                  overrides={{
                    BaseButton: {
                      props: {
                        title: translation?.filterBox?.searchButton?.title,
                      },
                      style: ({ $theme }) => ({
                        border: "1px solid " + $theme.colors.primary,
                        boxShadow:
                          "0.707px 0.707px 11.28px 0.72px rgba(0, 147, 221, 0.2)",
                        borderRadius: "4px",
                        fontSize: fontSizeMultiplier! * 10 + "px",
                        fontWeight: 500,
                        textTransform: "uppercase",
                        padding: "9px",
                        lineHeight: "10px",
                        width: "100%",
                        letterSpacing: "2px",
                      }),
                    },
                  }}
                  onClick={() => {
                    history.push({
                      search: getQueryString({
                        lang,
                        page: 0,
                        size: props?.appSettings?.pagination?.size?.[0] || 10,
                        from: startDate.toISOString(),
                        to: endDate.toISOString(),
                        searchInto:
                          searchInto && searchInto.length > 0
                            ? searchInto[0].id
                            : translation.filterBox?.searchInto?.items[0].value,
                        displayBy: displayBy.length
                          ? displayBy[0].id
                          : displayBy.id,
                      }),
                    });
                  }}
                  disabled={endDate < startDate || startDate > endDate}
                >
                  {translation?.filterBox?.searchButton?.displayText}
                </Button>
              </Block>
            </Block>
          </Block>
        </Block>
        <Block
          width={["100%", "100%", "36%", "25%"]}
          overrides={{
            Block: {
              style: () => ({
                display: "flex",
                flexDirection: "column",
              }),
            },
          }}
        >
          <Block
            overrides={{
              Block: {
                style: () => ({
                  backgroundColor: theme.colors.backgroundSecondary,
                  padding: "15px 20px",
                  width: "100%",
                  height: "100%",
                  borderRadius: "3px",
                  boxShadow:
                    "1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2)",
                  textDecoration: "none",
                  position: "relative",
                  boxSizing: "border-box",
                  fontFamily: "Open-sans, sans-serif",
                }),
              },
            }}
          >
            <H3
              overrides={{
                Block: {
                  style: () => ({
                    margin: "0px 0px 20px",
                    color: theme.colors.primary,
                    fontSize: fontSizeMultiplier! * 18 + "px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    fontWeight: 500,
                    textTransform: "uppercase",
                    display: "block",
                    fontFamily: "Open-sans, sans-serif",
                  }),
                },
              }}
            >
              {information.title}
            </H3>

            {information.items.map(
              (item: { key: any; value: any }, index: number) => {
                return (
                  <div key={index}>
                    <LabelXSmall
                      overrides={{
                        Block: {
                          style: () => ({
                            margin: "10px 0px 0px",
                            color: theme.colors.borderInverseOpaque,
                            fontWeight: 400,
                            fontSize: fontSizeMultiplier! * 10 + "px",
                            letterSpacing: ".25px",
                            textDecoration: "none",
                            fontFamily: "Open-sans, sans-serif",
                          }),
                        },
                      }}
                    >
                      {item.key && ReactHtmlParser(item.key)}
                    </LabelXSmall>
                    <LabelLarge
                      overrides={{
                        Block: {
                          style: () => ({
                            margin: "5px 0px 0px",
                            padding: "0px 0px 10px",
                            color: theme.colors.primary,
                            fontWeight: 500,
                            fontSize: fontSizeMultiplier! * 18 + "px",
                            lineHeight: "18px",
                            letterSpacing: ".25px",
                            textDecoration: "none",
                            borderBottom:
                              index === information.items.length - 1
                                ? "none"
                                : "1px solid " + theme.colors.backgroundInv,
                            fontFamily: "Open-sans, sans-serif",
                          }),
                        },
                      }}
                    >
                      {item.value}
                    </LabelLarge>
                  </div>
                );
              }
            )}

            {information?.moreInfo && (
              <LabelSmall
                as={(props) => (
                  <Link
                    to={"/" + information?.moreInfo?.slug}
                    title={information?.moreInfo?.title}
                    {...props}
                  >
                    {props.children}
                  </Link>
                )}
                overrides={{
                  Block: {
                    style: () => ({
                      color: theme.colors.primary,
                      textDecoration: "underline",
                      textTransform: "uppercase",
                      display: "block",
                      fontSize: fontSizeMultiplier! * 11 + "px",
                      letterSpacing: ".75px",
                      whiteSpace: "nowrap",
                    }),
                  },
                }}
              >
                {information?.moreInfo?.displayText}
              </LabelSmall>
            )}
          </Block>
        </Block>
      </Block>

      {items.length > 0 && (
        <div
          className={css({
            marginTop: "30px",
            marginBottom: "10px",
            backgroundColor: theme.colors.backgroundSecondary,
            borderRadius: "4px",
            padding: "20px",
          })}
        >
          <Label3
            overrides={{
              Block: {
                style: () => ({
                  fontSize: fontSizeMultiplier! * 14 + "px",
                  fontWeight: 500,
                  borderBottom: "1px solid " + theme.colors.backgroundInv,
                  paddingBottom: "15px",
                  color: "#707070",
                }),
              },
            }}
          >
            {translation?.summaryText}
            <span className={css({ color: theme.colors.primary })}>
              {" "}
              {from && format(new Date(from as string), formatDate)} -{" "}
              {to && format(new Date(to as string), formatDate)}
            </span>
          </Label3>
          <div
            className={css({
              margin: "20px 0 0 0",
            })}
          >
            <TableBuilder
              data={items}
              overrides={{
                Root: {
                  style: () => ({
                    borderRadius: "0px",
                    border: "none",
                    background: backgroundContrast(
                      theme.name,
                      theme.colors.contentAccent,
                      theme.colors.backgroundSecondary
                    ),
                  }),
                },
                Table: {
                  style: () => ({
                    overflow: "hidden",
                    background: backgroundContrast(
                      theme.name,
                      theme.colors.contentAccent,
                      theme.colors.backgroundSecondary
                    ),
                  }),
                },
                TableHeadCell: {
                  style: () => ({
                    fontSize: fontSizeMultiplier! * 10 + "px",
                    lineHeight: "10px",
                    border: "none",
                    backgroundColor: backgroundContrast(
                      theme.name,
                      theme.colors.primary,
                      theme.colors.backgroundOverlayDark
                    ),
                    color: backgroundContrast(
                      theme.name,
                      theme.colors.contentAccent,
                      theme.colors.primary
                    ),
                  }),
                },
                TableBodyCell: {
                  style: () => ({
                    fontSize: fontSizeMultiplier! * 11 + "px",
                    lineHeight: "14px",
                    color: backgroundContrast(
                      theme.name,
                      theme.colors.contentAccent,
                      theme.colors.borderInverseOpaque
                    ),
                    padding: "10px 5px",
                    borderBottom: "1px solid #e5e5e5",
                  }),
                },
                TableBody: {
                  style: () => ({
                    border:
                      "10px solid " +
                      backgroundContrast(
                        theme.name,
                        theme.colors.contentAccent,
                        theme.colors.backgroundSecondary
                      ),
                  }),
                },
              }}
            >
              <TableBuilderColumn header="">
                {(row) => {
                  if (row.isLink) {
                    return (
                      <Link
                        to={row.link.slug}
                        title={row.link.title}
                        style={{ color: theme.colors.accent50 }}
                      >
                        {row.link.displayText}
                      </Link>
                    );
                  } else {
                    return row.key;
                  }
                }}
              </TableBuilderColumn>
              <TableBuilderColumn header="Liczba odwiedzin">
                {(row) => {
                  return (
                    <div
                      className={css({
                        lineHeight: "12px",
                        color: theme.name.includes("contrast")
                          ? theme.colors.primary
                          : theme.colors.accent50,
                      })}
                    >
                      <div
                        className={css({
                          height: "12px",
                          background: theme.colors.primary,
                          display: "inline-block",
                          verticalAlign: "text-top",
                          marginRight: "5px",
                          width: (parseInt(row.rawValue) / maxValue) * 50 + "%",
                        })}
                      ></div>
                      {row.value}
                    </div>
                  );
                }}
              </TableBuilderColumn>
            </TableBuilder>
            {items?.length > 0 && (
              <Pagination
                {...{
                  startDate,
                  endDate,
                  searchInto,
                  translation,
                  headers,
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Stats;
