import { CustomLink } from "shared/CustomLink";

import styled from "styled-components";

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  border-top: ${({ contentInversePrimary, currentLevel }) =>
    currentLevel === 1 ? `1px solid ${contentInversePrimary}` : "none"};

  &:before {
    content: "";
    position: absolute;
    width: 1px;
    left: ${({ currentLevel }) => `${currentLevel * 20 - 15}px`};
    top: 0;
    height: 100%;
    background: ${({ contentInversePrimary, currentLevel }) =>
      currentLevel === 1 ? "none" : contentInversePrimary};
  }
`;

// padding: 0 10px 0 15px;

export const MenuItem = styled.li<{
  active: boolean;
  primary: string;
  backgroundLightAccent: string;
  buttonTertiaryHover: string;
  contentInversePrimary: string;
  currentLevel: number;
}>`
  font-size: 11px;
  text-transform: ${({ currentLevel }) =>
    currentLevel > 1 ? "inherit" : "uppercase"};
  width: 100%;
  line-height: 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: ${({ active, primary, contentInversePrimary, currentLevel }) =>
    currentLevel === 1
      ? `3px solid ${active ? primary : contentInversePrimary}`
      : "none"};

  background-color: ${({
    active,
    backgroundLightAccent,
    buttonTertiaryHover,
  }) => (active ? buttonTertiaryHover : backgroundLightAccent)};

  &:hover {
    background-color: ${({ buttonTertiaryHover }) => buttonTertiaryHover};
    cursor: pointer;
  }

  &:focus: {
    background-color: ${({ primary }) => primary};
  }

  &:first-child {
    ${Inner} {
      border-top: none;
    }
  }

  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
`;

export const MenuLinkItem = styled(CustomLink)<{
  primary: string;
  backgroundLightAccent: string;
  buttonTertiaryHover: string;
  contentInversePrimary: string;
  currentLevel: number;
  fontSizeMultiplier: number;
}>`
  color: ${({ primary }) => primary};
  display: flex;
  font-size: ${({ fontSizeMultiplier, currentLevel }) =>
    `${fontSizeMultiplier * (currentLevel > 1 ? 14 : 16)}px`};
  max-width: 100%;
  padding: ${({ currentLevel }) => `8px 15% 8px ${currentLevel * 20}px`};
  text-decoration: ${({ currentLevel }) =>
    currentLevel > 1 ? "underline" : "none"};
  display: flex;
  width: 100%;
  box-sizing: border-box;
`;

export const IconWrapper = styled.button<{ contentInversePrimary: string }>`
  background: transparent;

  width: 30px;
  padding: 5px;
  border: none;
  display: block;
  justify-content: center;
  position: absolute;
  height: 30px;
  // top: 0;
  right: 5px;
  // bottom: 0;
  margin: auto;

  &:hover {
    cursor: pointer;
    background: ${({ contentInversePrimary }) => contentInversePrimary};
  }
`;
