import React from "react";
import { Helmet } from "react-helmet-async";

import Article from "../Article/Article";

import Static from "../Static/Static";
import Page404 from "./../Page404/Page404";
import Changelog from "./../Changelog/Changelog";
import Search from "./../Search/Search";
import Stats from "./../Stats/Stats";
import Home from "../Home/Home";
import Sitemap from "./../Sitemap/Sitemap";
import Managers from "../Managers/index";
import OfficeData from "@containers/OfficeData/OfficeData";

import { Block } from "baseui/block";
import { StyledSpinnerNext } from "baseui/spinner";
import { Category } from "@containers/Category/Category";

type PProps = {
  lang: string;
  initData: any;
  data: any;
  error: any;
  loading: boolean;
  response: any;
};

const Page: React.FC<PProps> = (props) => {
  const { initData, error, loading, data, response, lang } = props || null;

  console.log(initData);

  if (error!)
    return (
      <Page404
        data={error}
        errorTranslation={initData?.layout?.errorPageTranslation}
      />
    );

  if (
    (!data && loading) ||
    (loading && data?.contentType !== 80 && data?.contentType !== 100)
  ) {
    return (
      <Block
        overrides={{
          Block: {
            style: () => ({
              width: "100%",
              height: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }),
          },
        }}
      >
        <StyledSpinnerNext />
      </Block>
    );
  }

  if (data?.contentData) {
    return (
      <React.Fragment>
        <Helmet
          defaultTitle={data?.contentData?.metadata?.author || "BIP"}
          titleTemplate={`BIP - ${data?.contentData?.metadata?.author} - %s`}
        >
          <html lang={lang ? lang.toLocaleLowerCase() : "pl"} />

          <title>
            {data?.contentData?.metadata?.title
              ? data?.contentData?.metadata?.title
              : null}
          </title>

          <meta
            name="author"
            content={data?.contentData?.metadata?.author || ""}
          />
          <meta
            name="description"
            content={data?.contentData?.metadata?.description || "BIP"}
          />
          <meta
            name="keywords"
            content={
              data?.contentData?.metadata?.keywords
                ? Object.values(data?.contentData?.metadata?.keywords).join(",")
                : "BIP"
            }
          />
          <meta
            name="deklaracja-dostępności"
            content={`${initData.frontendWebAppAddress}/dostepnosc-cyfrowa`}
          />
        </Helmet>
        {data?.contentType === 10 && (
          <Article data={data?.contentData} type={data?.contentType} />
        )}
        {data?.contentType === 20 && (
          <Category data={data?.contentData} response={response} />
        )}
        {data?.contentType === 30 && <Sitemap data={data?.contentData} />}
        {data?.contentType === 40 && (
          <Stats
            data={data?.contentData}
            response={response}
            layout={initData.layout}
            appSettings={initData.appSettings}
          />
        )}
        {data?.contentType === 50 && (
          <Article data={data?.contentData} type={data?.contentType} />
        )}
        {data?.contentType === 60 && (
          <Search
            data={data?.contentData}
            response={response}
            initData={initData.mainPage}
            layout={initData.layout}
            canSearchInArchive={initData.appSettings.canSearchInArchive}
          />
        )}
        {data?.contentType === 70 && (
          <Changelog
            data={data?.contentData}
            response={response}
            initData={initData.mainPage}
            layout={initData.layout}
            appSettings={initData.appSettings}
          />
        )}
        {data?.contentType === 80 && (
          <Home
            data={initData.mainPage}
            response={response}
            loading={loading}
          />
        )}
        {data?.contentType === 90 && <OfficeData data={data?.contentData} />}
        {data?.contentType === 100 && (
          <Home
            data={initData.mainPage}
            response={response}
            loading={loading}
            newsData={data?.contentData}
          />
        )}
        {data?.contentType === 110 && (
          <Article data={data?.contentData} type={data?.contentType} />
        )}
        {data?.contentType === 120 && <Managers data={data?.contentData} />}
        {data?.contentType === 130 && (
          <Article data={data?.contentData} type={data?.contentType} />
        )}
      </React.Fragment>
    );
  } else return <Static data={data} />;
};

export default Page;
