import React from "react";

import {
  Column,
  ColumnTitle,
  StatisticContent,
} from "@components/Footer/styles";

export const Statistics = ({ title, items, fontSizeMultiplier, accent }) => (
  <Column>
    <ColumnTitle
      {...{
        fontSize: fontSizeMultiplier! * 15,
        color: accent,
      }}
    >
      {title}
    </ColumnTitle>
    {items.map(({ key, big, value }, index) => (
      <div key={index}>
        <StatisticContent
          {...{
            fontSize: fontSizeMultiplier! * 11,
            color: accent,
          }}
        >
          {key}
          <br />
          {big ? (
            <ColumnTitle
              {...{
                fontSize: fontSizeMultiplier! * 16,
                color: accent,
              }}
            >
              {value}
            </ColumnTitle>
          ) : (
            value
          )}
        </StatisticContent>
      </div>
    ))}
  </Column>
);
