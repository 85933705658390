import React from "react";

import { HostingWrapper } from "@components/Footer/styles";
import { CustomLink } from "shared/CustomLink";

interface HostingProps {
  fontSizeMultiplier: number | null | undefined;
  accent: string;
}

export const Hosting = ({ fontSizeMultiplier, accent }: HostingProps) => (
  <HostingWrapper
    {...{
      fontSize: fontSizeMultiplier! * 10,
      color: accent,
    }}
  >
    CMS & Hosting: Nefeni Sp. z o.o.
  </HostingWrapper>
);
