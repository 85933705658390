import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  ReactNode,
} from "react";

import { useLocation, useHistory } from "react-router-dom";

import queryString from "query-string";
import { useStyletron } from "baseui";

import { useAccessibility } from "@contexts/AccessibilityContext";
import { ScrollPositionContext } from "@contexts/ScrollProvider";

import { List } from "./list";
import { ArticleSelect } from "./article-select";
import { NavItems } from "./nav-items";
import { Pagination } from "@containers/Pagination";

import { Title, Wrapper } from "./styles";
import { getQueryString } from "@utils/get-query-string";

type CProps = {
  data: any;
  response: any;
};

interface IListItem {
  title: string;
  items: ReactNode;
  pagination: ReactNode | null;
  select: ReactNode | null;
  visible: boolean;
}

export const Category: React.FC<CProps> = (props) => {
  const { categories, articles, displayText } = props?.data.item;
  const { translation } = props?.data;
  const { pathname, search } = useLocation();

  console.log({ translation });

  const {
    scrollLinkPosition,
    articleLinkId,
    changeScrollPosition,
    changeArticleId,
  } = useContext(ScrollPositionContext);

  const {
    page = 0,
    size = translation?.pagination?.size?.[0] || 10,
    sortType = -1,
  } = queryString.parse(search, {
    parseNumbers: true,
  });

  const history = useHistory();

  const { lang = "PL" } = queryString.parse(search);

  const [, theme] = useStyletron();

  const {
    colors: { primary, backgroundSecondary, backgroundInv },
  } = theme;

  const { headers } = props?.response;

  const { fontSizeMultiplier } = useAccessibility();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    history.push({
      pathname,
      search: getQueryString({
        lang,
        page,
        size,
        sortType: e.target.value,
      }),
    });
  };

  const getPageSize = (): number => {
    if (typeof page === "number" && typeof size === "number") {
      return page * size;
    }

    return 0;
  };

  const handleChangeArticleLinkId = (id: string) => {
    changeArticleId(id, "articleLink");
  };

  const handleScroll = () => {
    changeScrollPosition(window.scrollY, "articleLink");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    window.scrollTo({ top: scrollLinkPosition, behavior: "smooth" });
  }, []);

  const LISTS: IListItem[] = [
    {
      title: `${translation?.listNames?.categories}:`,
      items: (
        <NavItems
          {...{
            items: categories,
            lang,
            pageSize: 0,
            articleLinkId,
            handleChangeArticleLinkId,
          }}
        />
      ),
      pagination: null,
      select: null,
      visible: !!categories.length,
    },
    {
      title: `${translation?.listNames?.articles}:`,
      items: (
        <NavItems
          {...{
            items: articles,
            lang,
            pageSize: getPageSize(),
            articleLinkId,
            handleChangeArticleLinkId,
          }}
        />
      ),
      pagination: (
        <Pagination
          {...{
            translation,
            headers,
            params: {
              sortType,
            },
          }}
        />
      ),
      select: (
        <ArticleSelect
          {...{
            fontSizeMultiplier: fontSizeMultiplier || 1,
            handleChange,
            sortType,
            color: primary,
            secondaryColor: backgroundSecondary,
          }}
        />
      ),
      visible: !!articles.length,
    },
  ];

  return (
    <Wrapper
      {...{
        bgColor: backgroundSecondary,
        color: primary,
        fontSize: fontSizeMultiplier! * 16,
      }}
    >
      <Title borderColor={backgroundInv}>{displayText}</Title>
      {LISTS.map(({ title, items, pagination, select, visible }) => (
        <React.Fragment key={title}>
          {visible ? <List {...{ items, pagination, select, title }} /> : null}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};
