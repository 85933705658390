import React from "react";

import { processSlug } from "@utils/process-slug";

import {
  Column,
  ColumnTitle,
  Link,
  LinkWrapper,
} from "@components/Footer/styles";

export const Information = ({
  title,
  links,
  fontSizeMultiplier,
  accent,
  lang,
}) => (
  <Column>
    <ColumnTitle
      key={title}
      {...{
        fontSize: fontSizeMultiplier! * 15,
        color: accent,
      }}
    >
      {title}
    </ColumnTitle>
    <LinkWrapper
      {...{
        fontSize: fontSizeMultiplier! * 11,
        color: accent,
      }}
    >
      {links.map(({ displayText, slug, target, title, url }) => (
        <div key={title} title={title}>
          <Link
            {...{
              url,
              target: target === 20 ? "_blank" : "_self",
              slug: processSlug(slug, lang),
              title,
              fontSize: fontSizeMultiplier! * 11,
              color: accent,
            }}
          >
            {displayText}
          </Link>
        </div>
      ))}
    </LinkWrapper>
  </Column>
);
