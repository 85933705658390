import { CustomLink } from "shared/CustomLink";

import styled from "styled-components";

export const SideBannerLink = styled(CustomLink)`
  text-decoration: none;
`;

export const SideBannerButton = styled.button<{
  url: string;
  color: string;
  bgColor: string;
}>`
  background: ${({ bgColor }) => bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  margin: 20px auto 0;
  color: ${({ color }) => color};
  width: 100%;
  max-width: 300px;
  border-radius: 3px;
  height: 105px;
  box-shadow: 1.414px 1.414px 11.28px 0.72px rgba(136, 136, 136, 0.2);
  text-decoration: none;
  background-image: ${({ url }) => (url ? `url(${url})` : "none")};
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
`;

export const Title = styled.h4<{ color: string; fontSize: number }>`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-decoration: none;
`;
