import { CustomLink } from "shared/CustomLink";
import styled from "styled-components";

export const MenuItem = styled.li<{
  active: boolean;
  primary: string;
  backgroundLightAccent: string;
  buttonTertiaryHover: string;
  contentInversePrimary: string;
  isMobile: boolean;
  linkType: 10 | 20;
  count: number;
}>`
  background-color: ${({
    active,
    backgroundLightAccent,
    buttonTertiaryHover,
  }) => (active ? buttonTertiaryHover : backgroundLightAccent)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ count }) => `calc((100% / ${count}) - (50px / ${count}))`};
  position: relative;
  width: ${({ isMobile }) => isMobile && "100%"};

  border-bottom: ${({ active, primary, contentInversePrimary, isMobile }) =>
    !isMobile
      ? `3px solid ${active ? primary : contentInversePrimary}`
      : "none"};

  border-top: ${({ contentInversePrimary, isMobile }) =>
    isMobile ? `1px solid ${contentInversePrimary}` : "none"};

  &:hover {
    background-color: ${({ buttonTertiaryHover }) => buttonTertiaryHover};
    cursor: pointer;
  }

  &:focus: {
    background-color: ${({ primary }) => primary};
  }
`;

export const IconWrapper = styled.button<{ contentInversePrimary: string }>`
  position: absolute;
  background: transparent;
  right: 0;
  top: 0;
  width: 45px;
  height: 46px;
  border: none;

  &:hover {
    cursor: pointer;
    background: ${({ contentInversePrimary }) => contentInversePrimary};
  }
`;

export const Categories = styled.ul<{
  primary: string;
  backgroundLightAccent: string;
  buttonTertiaryHover: string;
  isOpen: boolean;
  isMobile: boolean;
}>`
  position: ${({ isMobile }) => (isMobile ? "relative" : "absolute")};
  top: ${({ isMobile }) => (isMobile ? "0" : "49px")};
  background: #fff;
  z-index: 10;
  width: 100%;
  list-style: none;

  padding: 0;
  box-shadow: ${({ isMobile }) =>
    isMobile ? "none" : "rgb(0 0 0 / 16%) 0px 4px 16px"};
  max-height: ${({ isOpen }) => (isOpen ? "320px" : "0")};
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ buttonTertiaryHover }) => buttonTertiaryHover};
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: ${({ buttonTertiaryHover }) => buttonTertiaryHover};
  }

  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ primary }) => primary};
  }
`;

export const MenuLinkItem = styled(CustomLink)<{
  primary: string;
  backgroundLightAccent: string;
  buttonTertiaryHover: string;
  isOpen: boolean;
  isMobile: boolean;
}>`
  border-left: ${({ active, primary, contentInversePrimary, isMobile }) =>
    isMobile
      ? `3px solid ${active ? primary : contentInversePrimary}`
      : "none"};

  border-bottom: ${({ contentInversePrimary, isMobile }) =>
    isMobile ? `1px solid ${contentInversePrimary}` : "none"};
  color: ${({ primary }) => primary};
  display: block;
  font-size: ${({ fontSizeMultiplier }) => `${fontSizeMultiplier * 16}px`};
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  overflow: hidden;
  padding: 14px 20px 12px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
`;

export const DisplayText = styled.span`
  display: block;
  max-width: calc(100% - 25px);
  overflow: hidden;
  text-overflow: ellipsis;
`;
